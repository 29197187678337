<template>
  <div style="width: 100%">
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        INVENTARIO DE PRODUCTOS
      </v-card-title>
      <v-card-text> </v-card-text>
      <!-- agregar inputs de busqueda -->
      <v-row class="mx-2 mt-1">
        <v-col cols="12" lg="3" md="3">
          <v-text-field
            v-model="dias"
            append-icon="mdi-calendar-month"
            label="Días"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3">
          <v-text-field
            v-model="periodos_year_actual"
            append-icon="mdi-calendar-month"
            label="Periodos año actual"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3">
          <v-text-field
            v-model="periodos_year_anterior"
            append-icon="mdi-calendar-month"
            label="Periodos año anterior"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3">
          <v-btn class="mb-2 mt-3" @click="getStock()">Buscar</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- Sidebar Personalizado -->
    <div
      style="
        width: 100%;
        padding: 10px;
        margin-bottom: 1rem;
        margin-top: 2rem;
        background: #f4f4f4;
      "
    >
      <h3>Mostrar/Ocultar Columnas</h3>
      <div style="display: flex; flex-wrap: wrap">
        <div
          v-for="col in filteredColumnsDefs"
          :key="col.field || col.headerName"
          style="margin-right: 20px"
        >
          <template v-if="col.children">
            <div>
              <strong>{{ col.headerName }}</strong>
              <div
                v-for="child in col.children"
                :key="child.field || child.headerName"
                style="margin-left: 10px"
              >
                <label>
                  <input
                    type="checkbox"
                    :checked="!child.hide"
                    @change="toggleColumn(child)"
                  />
                  {{ child.headerName }}
                </label>
              </div>
            </div>
          </template>
          <template v-else>
            <label>
              <input
                type="checkbox"
                :checked="!col.hide"
                @change="toggleColumn(col)"
              />
              {{ col.headerName }}
            </label>
          </template>
        </div>
      </div>
      <h3 class="mt-3">Mostrar/Ocultar Sucursales</h3>
      <div style="display: flex; flex-wrap: wrap">
        <label
          v-for="sucursal in sucursales"
          :key="sucursal"
          style="margin-right: 20px"
        >
          <input
            type="checkbox"
            :checked="!ocultarSucursales[sucursal]"
            @change="toggleSucursal(sucursal)"
          />
          {{ sucursal }}
        </label>
      </div>
      <h3 class="mt-3">Mostrar/Ocultar Datos</h3>
      <div style="display: flex; flex-wrap: wrap">
        <v-checkbox
          v-model="showAll"
          label="Mostrar Inactivos"
          @change="actualizarTabla"
          class="mr-2"
        ></v-checkbox>

        <!-- NUEVOS CHECKBOXES -->
        <v-checkbox
          v-model="cargarEnvios"
          label="Mostrar datos calculados con Envios"
          @change="getStock()"
          class="mr-2"
        ></v-checkbox>
        <v-checkbox
          v-model="cargarFaltantes"
          label="Mostrar datos calculados con Faltantes"
          @change="getStock()"
        ></v-checkbox>
      </div>
    </div>

    <!-- Botón para exportar a Excel -->
    <v-btn @click="exportToCSV" color="primary" class="mb-2">
      Exportar a Excel
    </v-btn>

    <v-btn @click="autoSizeAllColumns" color="primary" class="ml-2 mb-2">
      Ajustar columnas
    </v-btn>

    <!-- Tabla AG Grid -->
    <ag-grid-vue
      style="width: 100%; height: 600px"
      class="ag-theme-quartz"
      :columnDefs="columnDefs"
      :rowData="filteredRowData"
      :defaultColDef="defaultColDef"
      :domLayaout="'autoHeight'"
      rowSelection="multiple"
      :pagination="true"
      :localeText="localeText"
      @grid-ready="onGridReady"
      @rowClicked="rowClicked"
    ></ag-grid-vue>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialogVerProducto"
    >
      <v-card>
        <v-container fluid>
          <!-- Título del diálogo -->
          <v-card-title class="text-center">
            Detalles del Producto: {{ selectedItem?.codigo_producto }} -
            {{ selectedItem?.nombre_producto }}
            <v-spacer></v-spacer>
            <v-btn icon color="blue darken-1" text @click="cerrarDialog()">
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Gráfica de barras 
          <v-row justify="center">
            <v-col cols="12" md="6">
              <bar-chart-stock
                title="Stock Global"
                :envios="Number(globalEnvios)"
                :inventario="Number(selectedItem?.inv_total || 0)"
                :faltantes="Number(globalFaltantes)"
                :pronostico="Number(selectedItem?.pro_total || 0)"
              ></bar-chart-stock>
            </v-col>
          </v-row>
          -->
          <!-- 
          <v-row>
            <v-col
              cols="12"
              md="4"
              v-for="sucursal in sucursales"
              :key="sucursal"
            >
              <bar-chart-stock
                :title="'Stock ' + sucursal"
                :inventario="
                  Number(
                    selectedItem ? selectedItem[`inv_${sucursal}`] || 0 : 0
                  )
                "
                :envios="
                  Number(
                    selectedItem
                      ? selectedItem[`totalEnvios_${sucursal}`] || 0
                      : 0
                  )
                "
                :pronostico="
                  Number(
                    selectedItem ? selectedItem[`pro_${sucursal}`] || 0 : 0
                  )
                "
                :faltantes="
                  Number(
                    selectedItem
                      ? selectedItem[`totalFaltantes_${sucursal}`] || 0
                      : 0
                  )
                "
              ></bar-chart-stock>
            </v-col>
          </v-row>
          -->
          <v-row justify="center" class="d-flex">
            <v-col cols="12" md="4">
              <h5 class="text-center">
                Stock: {{ selectedItem?.stock_total.toFixed(2) }}
              </h5>
              <bar-chart-stock
                title="Stock Global"
                :sucursalesData="[
                  {
                    sucursal: 'Global',
                    stock: Number(selectedItem?.stock_total.toFixed(2)),
                    inventario: Number(selectedItem?.inv_total || 0),
                    envios: Number(globalEnvios),
                    pronostico: Number(selectedItem?.pro_total || 0),
                    faltantes: Number(globalFaltantes),
                  },
                ]"
              ></bar-chart-stock>
            </v-col>
            <v-col cols="12" md="9">
              <bar-chart-stock
                title="Stock Sucursales"
                :sucursalesData="sucursalesData"
              ></bar-chart-stock>
            </v-col>
          </v-row>

          <!-- Contenido -->
          <v-card-text>
            <v-row no-gutters>
              <!-- Columna de la card Global -->
              <v-col cols="12" md="3">
                <!-- Para que la card Global ocupe todo el alto de la columna, usamos clases de flex -->
                <v-card
                  class="pa-3 mr-7 d-flex flex-column"
                  style="height: 100%"
                >
                  <v-card-title class="d-flex justify-center text-center"
                    >Global</v-card-title
                  >
                  <v-card-text class="text-center" style="font-size: 20px">
                    <b>Inventario Total: </b>{{ selectedItem?.inv_total }}
                    <br />
                    <b>Pronostico Total: </b>{{ selectedItem?.pro_total }}
                    <br />
                    <b>Stock Total: </b
                    >{{ selectedItem?.stock_total.toFixed(2) }}
                    <br />
                    <b>Total de Envios: </b>{{ globalEnvios }}
                    <br />
                    <b>Total de Faltantes: </b>{{ globalFaltantes }}
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Columna de las cards de sucursales -->
              <v-col cols="12" md="8">
                <!-- Organizar las cards de sucursales en filas de 3 -->
                <v-row>
                  <!-- Card Mexicali -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-4 mb-4">
                      <v-card-title class="d-flex justify-center text-center"
                        >Mexicali</v-card-title
                      >
                      <v-card-text class="text-center">
                        <b>Inventario: </b>{{ selectedItem?.inv_Mexicali }}
                        <br />
                        <b>Pronostico: </b>{{ selectedItem?.pro_Mexicali }}
                        <br />
                        <b>Stock: </b
                        >{{ selectedItem?.stock_Mexicali.toFixed(2) }}
                        <br />
                        <b>Envios: </b>{{ selectedItem?.totalEnvios_Mexicali }}
                        <div
                          v-if="
                            enviosAgrupadosFecha.Mexicali &&
                            enviosAgrupadosFecha.Mexicali.length
                          "
                        >
                          <div
                            v-for="(
                              envio, index
                            ) in enviosAgrupadosFecha.Mexicali"
                            :key="index"
                          >
                            <p>
                              <b>Fecha de llegada:</b> {{ envio.fecha }}<br />
                              <b>Piezas:</b> {{ envio.piezas }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay envíos registrados para Mexicali.</p>
                        </div>

                        <br />
                        <b>Faltantes: </b
                        >{{ selectedItem?.totalFaltantes_Mexicali }}
                        <div
                          v-if="
                            faltantesAgrupados.Mexicali &&
                            faltantesAgrupados.Mexicali.length
                          "
                        >
                          <div
                            v-for="(
                              faltante, index
                            ) in faltantesAgrupados.Mexicali"
                            :key="index"
                          >
                            <p>
                              <b>Vendedor:</b> {{ faltante.vendedor }}<br />
                              <b>Piezas:</b> {{ faltante.cantidad }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay faltantes registrados para Mexicali.</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <!-- Card Tijuana -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-4 mb-4">
                      <v-card-title class="d-flex justify-center text-center"
                        >Tijuana</v-card-title
                      >
                      <v-card-text class="text-center">
                        <b>Inventario: </b>{{ selectedItem?.inv_Tijuana }}
                        <br />
                        <b>Pronostico: </b>{{ selectedItem?.pro_Tijuana }}
                        <br />
                        <b>Stock: </b
                        >{{ selectedItem?.stock_Tijuana.toFixed(2) }}
                        <br />
                        <b>Envios: </b>{{ selectedItem?.totalEnvios_Tijuana }}
                        <br />
                        <div
                          v-if="
                            enviosAgrupadosFecha.Tijuana &&
                            enviosAgrupadosFecha.Tijuana.length
                          "
                        >
                          <div
                            v-for="(
                              envio, index
                            ) in enviosAgrupadosFecha.Tijuana"
                            :key="index"
                          >
                            <p>
                              <b>Fecha de llegada:</b> {{ envio.fecha }}<br />
                              <b>Piezas:</b> {{ envio.piezas }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay envíos registrados para Tijuana.</p>
                        </div>
                        <b>Faltantes: </b
                        >{{ selectedItem?.totalFaltantes_Tijuana }}
                        <div
                          v-if="
                            faltantesAgrupados.Tijuana &&
                            faltantesAgrupados.Tijuana.length
                          "
                        >
                          <div
                            v-for="(
                              faltante, index
                            ) in faltantesAgrupados.Tijuana"
                            :key="index"
                          >
                            <p>
                              <b>Vendedor:</b> {{ faltante.vendedor }}<br />
                              <b>Piezas:</b> {{ faltante.cantidad }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay faltantes registrados para Tijuana.</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <!-- Card Ensenada -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-4 mb-4">
                      <v-card-title class="d-flex justify-center text-center"
                        >Ensenada</v-card-title
                      >
                      <v-card-text class="text-center">
                        <b>Inventario: </b>{{ selectedItem?.inv_Ensenada }}
                        <br />
                        <b>Pronostico: </b>{{ selectedItem?.pro_Ensenada }}
                        <br />
                        <b>Stock: </b
                        >{{ selectedItem?.stock_Ensenada.toFixed(2) }}
                        <br />
                        <b>Envios: </b>{{ selectedItem?.totalEnvios_Ensenada }}
                        <div
                          v-if="
                            enviosAgrupadosFecha.Ensenada &&
                            enviosAgrupadosFecha.Ensenada.length
                          "
                        >
                          <div
                            v-for="(
                              envio, index
                            ) in enviosAgrupadosFecha.Ensenada"
                            :key="index"
                          >
                            <p>
                              <b>Fecha de llegada:</b> {{ envio.fecha }}<br />
                              <b>Piezas:</b> {{ envio.piezas }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay envíos registrados para Ensenada.</p>
                        </div>
                        <br />
                        <b>Faltantes: </b
                        >{{ selectedItem?.totalFaltantes_Ensenada }}
                        <div
                          v-if="
                            faltantesAgrupados.Ensenada &&
                            faltantesAgrupados.Ensenada.length
                          "
                        >
                          <div
                            v-for="(
                              faltante, index
                            ) in faltantesAgrupados.Ensenada"
                            :key="index"
                          >
                            <p>
                              <b>Vendedor:</b> {{ faltante.vendedor }}<br />
                              <b>Piezas:</b> {{ faltante.cantidad }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay faltantes registrados para Ensenada.</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <!-- Card Hermosillo -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-4 mb-4">
                      <v-card-title class="d-flex justify-center text-center"
                        >Hermosillo</v-card-title
                      >
                      <v-card-text class="text-center">
                        <b>Inventario: </b>{{ selectedItem?.inv_Hermosillo }}
                        <br />
                        <b>Pronostico: </b>{{ selectedItem?.pro_Hermosillo }}
                        <br />
                        <b>Stock: </b
                        >{{ selectedItem?.stock_Hermosillo.toFixed(2) }}
                        <br />
                        <b>Envios: </b
                        >{{ selectedItem?.totalEnvios_Hermosillo }}
                        <div
                          v-if="
                            enviosAgrupadosFecha.Hermosillo &&
                            enviosAgrupadosFecha.Hermosillo.length
                          "
                        >
                          <div
                            v-for="(
                              envio, index
                            ) in enviosAgrupadosFecha.Hermosillo"
                            :key="index"
                          >
                            <p>
                              <b>Fecha de llegada:</b> {{ envio.fecha }}<br />
                              <b>Piezas:</b> {{ envio.piezas }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay envíos registrados para Hermosillo.</p>
                        </div>
                        <br />
                        <b>Faltantes: </b
                        >{{ selectedItem?.totalFaltantes_Hermosillo }}
                        <div
                          v-if="
                            faltantesAgrupados.Hermosillo &&
                            faltantesAgrupados.Hermosillo.length
                          "
                        >
                          <div
                            v-for="(
                              faltante, index
                            ) in faltantesAgrupados.Hermosillo"
                            :key="index"
                          >
                            <p>
                              <b>Vendedor:</b> {{ faltante.vendedor }}<br />
                              <b>Piezas:</b> {{ faltante.cantidad }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay faltantes registrados para Hermosillo.</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <!-- Card La Paz -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-4 mb-4">
                      <v-card-title class="d-flex justify-center text-center"
                        >La Paz</v-card-title
                      >
                      <v-card-text class="text-center">
                        <b>Inventario: </b>{{ selectedItem?.inv_LaPaz }} <br />
                        <b>Pronostico: </b>{{ selectedItem?.pro_LaPaz }} <br />
                        <b>Stock: </b>{{ selectedItem?.stock_LaPaz.toFixed(2) }}
                        <br />
                        <b>Envios: </b>{{ selectedItem?.totalEnvios_LaPaz }}
                        <br />
                        <div
                          v-if="
                            enviosAgrupadosFecha.LaPaz &&
                            enviosAgrupadosFecha.LaPaz.length
                          "
                        >
                          <div
                            v-for="(envio, index) in enviosAgrupadosFecha.LaPaz"
                            :key="index"
                          >
                            <p>
                              <b>Fecha de llegada:</b> {{ envio.fecha }}<br />
                              <b>Piezas:</b> {{ envio.piezas }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay envíos registrados para La Paz.</p>
                        </div>
                        <b>Faltantes: </b
                        >{{ selectedItem?.totalFaltantes_LaPaz }}
                        <div
                          v-if="
                            faltantesAgrupados.LaPaz &&
                            faltantesAgrupados.LaPaz.length
                          "
                        >
                          <div
                            v-for="(
                              faltante, index
                            ) in faltantesAgrupados.LaPaz"
                            :key="index"
                          >
                            <p>
                              <b>Vendedor:</b> {{ faltante.vendedor }}<br />
                              <b>Piezas:</b> {{ faltante.cantidad }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay faltantes registrados para La Paz.</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <!-- Card Obregón -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-4 mb-4">
                      <v-card-title class="d-flex justify-center text-center"
                        >Obregón</v-card-title
                      >
                      <v-card-text class="text-center">
                        <b>Inventario: </b>{{ selectedItem?.inv_Obregon }}
                        <br />
                        <b>Pronostico: </b>{{ selectedItem?.pro_Obregon }}
                        <br />
                        <b>Stock: </b
                        >{{ selectedItem?.stock_Obregon.toFixed(2) }}
                        <br />
                        <b>Envios: </b>{{ selectedItem?.totalEnvios_Obregon }}
                        <div
                          v-if="
                            enviosAgrupadosFecha.Obregon &&
                            enviosAgrupadosFecha.Obregon.length
                          "
                        >
                          <div
                            v-for="(
                              envio, index
                            ) in enviosAgrupadosFecha.Obregon"
                            :key="index"
                          >
                            <p>
                              <b>Fecha de llegada:</b> {{ envio.fecha }}<br />
                              <b>Piezas:</b> {{ envio.piezas }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay envíos registrados para Obregón.</p>
                        </div>
                        <br />
                        <b>Faltantes: </b
                        >{{ selectedItem?.totalFaltantes_Obregon }}
                        <div
                          v-if="
                            faltantesAgrupados.Obregon &&
                            faltantesAgrupados.Obregon.length
                          "
                        >
                          <div
                            v-for="(
                              faltante, index
                            ) in faltantesAgrupados.Obregon"
                            :key="index"
                          >
                            <p>
                              <b>Vendedor:</b> {{ faltante.vendedor }}<br />
                              <b>Piezas:</b> {{ faltante.cantidad }}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>No hay faltantes registrados para Obregón.</p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridVue } from "ag-grid-vue";
import pako from "pako";
import Papa from "papaparse";
import axios from "axios";
import BarChartStock from "@/components/BarChartStock.vue";

export default {
  components: { AgGridVue, BarChartStock },
  data() {
    return {
      ChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      selectedItem: null,
      dialogVerProducto: false,
      dias: "" || 30,
      periodos_year_actual: "" || 3,
      periodos_year_anterior: "" || 3,
      gridApi: null,
      showAll: false,
      cargarEnvios: true,
      cargarFaltantes: true,
      maxImportance: 0,
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo_producto",
          hide: false,
        },
        {
          headerName: "NOMBRE",
          field: "nombre_producto",
          hide: false,
        },
        {
          headerName: "Estado",
          field: "estado",
          hide: true, // Ocultar la columna de estado

          filter: "agSetColumnFilter", // Usar filtro de conjunto
          filterParams: {
            values: ["1", "0"],
            valueFormatter: function (params) {
              return params.value === "1" ? "Activo" : "Inactivo";
            },
          },
        },

        {
          headerName: "INVENTARIO",
          headerClass: "header-class2",
          children: [
            {
              headerName: "MXL",
              field: "inv_Mexicali",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class2",
            },
            {
              headerName: "TIJ",
              field: "inv_Tijuana",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class2",
            },
            {
              headerName: "ENS",
              field: "inv_Ensenada",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class2",
            },
            {
              headerName: "HLL",
              field: "inv_Hermosillo",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class2",
            },
            {
              headerName: "LPZ",
              field: "inv_LaPaz",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class2",
            },
            {
              headerName: "OBR",
              field: "inv_Obregon",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class2",
            },
            {
              headerName: "Total",
              field: "inv_total",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class2",
            },
          ],
        },
        {
          headerName: "PRONOSTICO",
          headerClass: "header-class",
          children: [
            {
              headerName: "MXL",
              field: "pro_Mexicali",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class",
            },
            {
              headerName: "TIJ",
              field: "pro_Tijuana",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class",
            },
            {
              headerName: "ENS",
              field: "pro_Ensenada",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class",
            },
            {
              headerName: "HLL",
              field: "pro_Hermosillo",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class",
            },
            {
              headerName: "LPZ",
              field: "pro_LaPaz",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class",
            },
            {
              headerName: "OBR",
              field: "pro_Obregon",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class",
            },
            {
              headerName: "Total",
              field: "pro_total",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class",
            },
          ],
        },
        {
          headerName: "STOCK",
          headerClass: "header-class3",
          children: [
            {
              headerName: "MXL",
              field: "stock_Mexicali",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class3",
              cellStyle: (params) => {
                const importance = params.data.importancia;
                const maxImportance = this.maxImportance;
                const cantidad = params.data.stock_Mexicali;
                return {
                  backgroundColor: this.getColorByImportance(
                    importance,
                    maxImportance,
                    cantidad
                  ),
                };
              },
            },
            {
              headerName: "TIJ",
              field: "stock_Tijuana",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class3",
              cellStyle: (params) => {
                const importance = params.data.importancia;
                const maxImportance = this.maxImportance;
                const cantidad = params.data.stock_Tijuana;
                return {
                  backgroundColor: this.getColorByImportance(
                    importance,
                    maxImportance,
                    cantidad
                  ),
                };
              },
            },
            {
              headerName: "ENS",
              field: "stock_Ensenada",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class3",
              cellStyle: (params) => {
                const importance = params.data.importancia;
                const maxImportance = this.maxImportance;
                const cantidad = params.data.stock_Ensenada;
                return {
                  backgroundColor: this.getColorByImportance(
                    importance,
                    maxImportance,
                    cantidad
                  ),
                };
              },
            },
            {
              headerName: "HLL",
              field: "stock_Hermosillo",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class3",
              cellStyle: (params) => {
                const importance = params.data.importancia;
                const maxImportance = this.maxImportance;
                const cantidad = params.data.stock_Hermosillo;
                return {
                  backgroundColor: this.getColorByImportance(
                    importance,
                    maxImportance,
                    cantidad
                  ),
                };
              },
            },
            {
              headerName: "LPZ",
              field: "stock_LaPaz",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class3",
              cellStyle: (params) => {
                const importance = params.data.importancia;
                const maxImportance = this.maxImportance;
                const cantidad = params.data.stock_LaPaz;
                return {
                  backgroundColor: this.getColorByImportance(
                    importance,
                    maxImportance,
                    cantidad
                  ),
                };
              },
            },
            {
              headerName: "OBR",
              field: "stock_Obregon",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class3",
              cellStyle: (params) => {
                const importance = params.data.importancia;
                const maxImportance = this.maxImportance;
                const cantidad = params.data.stock_Obregon;
                return {
                  backgroundColor: this.getColorByImportance(
                    importance,
                    maxImportance,
                    cantidad
                  ),
                };
              },
            },
            {
              headerName: "Total",
              field: "stock_total",
              hide: false,
              valueFormatter: this.formatNumber,
              headerClass: "header-class3",
              cellStyle: (params) => {
                const importance = params.data.importancia;
                const maxImportance = this.maxImportance;
                const cantidad = params.data.stock_total;
                return {
                  backgroundColor: this.getColorByImportance(
                    importance,
                    maxImportance,
                    cantidad
                  ),
                };
              },
            },
          ],
        },
        {
          headerName: "PRECIO PUBLICO",
          field: "precio_publico",
          hide: false,
          valueFormatter: this.formatNumber,
        },
        {
          headerName: "IMPORTANCIA",
          field: "importancia",
          hide: false,
          valueFormatter: this.formatNumber,
        },
      ],
      rowData: [],
      filteredRowData: [],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        checkboxSelection: false,
        floatingFilter: true,
      },
      localeText: {
        // Personalizar mensajes del filtro
        selectAll: "Seleccionar todo",
        searchOoo: "Buscar...",
        blanks: "Vacío",
        noMatches: "Sin coincidencias",
        filterOoo: "Filtrar...",
        equals: "Es igual a",
        notEqual: "No es igual a",
        lessThan: "Menor que",
        greaterThan: "Mayor que",
        inRange: "En el rango",
        contains: "Contiene",
        startsWith: "Empieza con",
        endsWith: "Termina con",
        notContains: "No contiene",
        blank: "Vacio",
        notBlank: "No vacio",
        page: "Página",
        of: "de",
        to: "a",
        pageSizeSelectorLabel: "Tamaño de la página",
        noRowsToShow: "No hay filas para mostrar",
        andCondition: "Y",
        orCondition: "O",
      },
      envios: [],
      faltantes: [],
      sucursales: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "Hermosillo",
        "LaPaz",
        "Obregon",
      ],
      ocultarSucursales: {
        Mexicali: false,
        Tijuana: false,
        Ensenada: false,
        Hermosillo: false,
        LaPaz: false,
        Obregon: false,
      },
      usuarios: [],
    };
  },
  methods: {
    getColorByImportance(value, maxImportance, cantidad) {
      const importancia = value / maxImportance;
      const porcentaje = Math.abs(importancia) * 100;
      const intensidad = (cantidad / 100) * importancia;
      let alpha = (porcentaje + intensidad) / 1;
      alpha = Math.min(alpha, 0.5);
      alpha = Math.max(alpha, 0.2);

      //console.log("Importancia:", importancia);
      //si el numero es mayor a 0 entonces es azul si no es rojo y si es 0 es blanco
      if (intensidad > 0) {
        return `rgba(28, 121, 198, ${alpha})`;
      } else if (intensidad < 0) {
        return `rgba(205, 27, 27, ${alpha})`;
      } else {
        return "white";
      }
    },

    async getEnvios() {
      try {
        const response = await axios.get("/envios/get");
        this.envios = response.data;
        console.log("Envios obtenidos:", this.envios);

        this.actualizarInventario();
      } catch (error) {
        console.error("Error al obtener los envíos:", error);
      }
    },

    actualizarInventario() {
      const inventario = this.rowData || [];
      const envios = this.envios || [];

      // Iteramos sobre los envíos
      envios.forEach((envio) => {
        const sucursal = envio.sucursal; // Obtener la sucursal destino del envío

        /*console.warn(`Estructura del envio:`, envio);*/

        if (!envio.envios || !Array.isArray(envio.envios)) {
          console.warn(
            `El envio con destino ${sucursal} no tiene productos`,
            envio
          );
          return;
        }

        // Iteramos sobre los envíos dentro de cada sucursal
        envio.envios.forEach((envioDetalle) => {
          if (
            !envioDetalle.productos ||
            !Array.isArray(envioDetalle.productos.lista_de_productos)
          ) {
            console.warn(
              `El envio con id ${envioDetalle.id} no tiene productos`,
              envioDetalle
            );
            return;
          }

          const fechaLlegada = envioDetalle.fecha_llegada;

          // Iteramos sobre los productos del envío
          envioDetalle.productos.lista_de_productos.forEach((producto) => {
            console.log(`Producto:`, producto);
            // Buscamos si el producto ya existe en el inventario
            let productoExistente = inventario.find(
              (item) => item.codigo_producto === producto.codigo_producto
            );

            if (productoExistente) {
              const sucursalKey = `inv_${sucursal}`;
              if (productoExistente[sucursalKey] !== undefined) {
                productoExistente[sucursalKey] =
                  Number(productoExistente[sucursalKey]) +
                  Number(producto.cantidad);
                productoExistente.inv_total =
                  Number(productoExistente.inv_total) +
                  Number(producto.cantidad);
              } else {
                productoExistente[sucursalKey] = Number(producto.cantidad);
                productoExistente.inv_total =
                  Number(productoExistente.inv_total) +
                  Number(producto.cantidad);
              }

              // Add envio details
              // Almacenamos la información del envío para la sucursal
              if (!productoExistente.enviosPorSucursal) {
                productoExistente.enviosPorSucursal = {};
              }
              if (!productoExistente.enviosPorSucursal[sucursal]) {
                productoExistente.enviosPorSucursal[sucursal] = [];
              }
              productoExistente.enviosPorSucursal[sucursal].push({
                fecha_llegada: fechaLlegada,
                piezas: Number(producto.cantidad),
              });

              // Si deseas, también puedes sumar un total
              if (!productoExistente[`totalEnvios_${sucursal}`]) {
                productoExistente[`totalEnvios_${sucursal}`] = 0;
              }
              productoExistente[`totalEnvios_${sucursal}`] += Number(
                producto.cantidad
              );

              //console.log("Holaaaaaaa", productoExistente.enviosPorSucursal);
              //End Add envio details
            } else {
              // Si el producto no existe, no hacemos nada
              console.warn(
                `El producto con codigo ${producto.codigo_producto} no existe en el inventario`
              );
            }
          });
        });
      });

      console.log("Inventario actualizado:", inventario);
      this.recalcularStock();
      //this.rowData = inventario;
    },

    async getFaltantes() {
      try {
        const response = await axios.get("/faltantes/get_abiertos");
        this.faltantes = response.data;
        console.log("Faltantes obtenidos:", this.faltantes);
        this.actualizarPronostico();
      } catch (error) {
        console.error("Error al obtener los faltantes:", error);
      }
    },

    actualizarPronostico() {
      const inventario = this.rowData || [];
      const faltantes = this.faltantes || [];

      // Iteramos sobre los faltantes
      faltantes.forEach((faltante) => {
        const sucursal = faltante.sucursal; // Obtener la sucursal del faltante

        if (!faltante.faltantes || !Array.isArray(faltante.faltantes)) {
          console.warn(`La sucursal ${sucursal} no tiene faltantes`, faltante);
          return;
        }

        // Iteramos sobre los faltantes dentro de cada sucursal
        faltante.faltantes.forEach((faltanteDetalle) => {
          console.log(`Faltante:`, faltanteDetalle);
          // Buscamos si el producto ya existe en el inventario
          let productoExistente = inventario.find(
            (item) => item.codigo_producto === faltanteDetalle.codigo_producto
          );

          if (productoExistente) {
            const sucursalKey = `pro_${sucursal}`;
            if (productoExistente[sucursalKey] !== undefined) {
              productoExistente[sucursalKey] =
                Number(productoExistente[sucursalKey]) +
                Number(faltanteDetalle.cantidad);
              productoExistente.pro_total =
                Number(productoExistente.pro_total) +
                Number(faltanteDetalle.cantidad);
            } else {
              productoExistente[sucursalKey] = Number(faltanteDetalle.cantidad);
              productoExistente.pro_total =
                Number(productoExistente.pro_total) +
                Number(faltanteDetalle.cantidad);
            }
            // Add faltante details
            // Agregamos los detalles del faltante agrupados por vendedor
            // Aseguramos que exista la propiedad para la agrupación (por sucursal)
            if (!productoExistente.faltantesPorVendedor) {
              productoExistente.faltantesPorVendedor = {};
            }
            if (!productoExistente.faltantesPorVendedor[sucursal]) {
              productoExistente.faltantesPorVendedor[sucursal] = [];
            }
            // Agregamos el detalle con el nombre del vendedor y la cantidad
            //buscar el nombre del vendedor en la lista de usuarios por su id
            const nombreVendedor = this.getNombreVendedor(
              faltanteDetalle.vendedor
            );
            productoExistente.faltantesPorVendedor[sucursal].push({
              vendedor: nombreVendedor, // Asegúrate que esta propiedad existe en faltanteDetalle
              cantidad: Number(faltanteDetalle.cantidad),
            });

            // Si deseas, también puedes sumar un total
            if (!productoExistente[`totalFaltantes_${sucursal}`]) {
              productoExistente[`totalFaltantes_${sucursal}`] = 0;
            }
            productoExistente[`totalFaltantes_${sucursal}`] += Number(
              faltanteDetalle.cantidad
            );

            /* console.log(
              "HolaaaaaaaVendedor",
              productoExistente.faltantesPorVendedor
            );*/
            // End Add faltante details
          } else {
            // Si el producto no existe, no hacemos nada
            console.warn(
              `El producto con codigo ${faltanteDetalle.codigo_producto} no existe en el inventario con faltantes`
            );
          }
        });
      });

      console.log("Inventario actualizado con faltantes:", inventario);
      this.recalcularStock();
    },

    recalcularStock() {
      const inventario = this.rowData || [];
      inventario.forEach((item) => {
        item.stock_Mexicali = item.inv_Mexicali - item.pro_Mexicali;
        item.stock_Tijuana = item.inv_Tijuana - item.pro_Tijuana;
        item.stock_Ensenada = item.inv_Ensenada - item.pro_Ensenada;
        item.stock_Hermosillo = item.inv_Hermosillo - item.pro_Hermosillo;
        item.stock_LaPaz = item.inv_LaPaz - item.pro_LaPaz;
        item.stock_Obregon = item.inv_Obregon - item.pro_Obregon;
        // calcular la suma de stock_total de todas las sucursales
        item.stock_total =
          item.stock_Mexicali +
          item.stock_Tijuana +
          item.stock_Ensenada +
          item.stock_Hermosillo +
          item.stock_LaPaz +
          item.stock_Obregon;
      });
      this.rowData = inventario;
      console.log("Stock recalculado:", inventario);

      if (this.gridApi) {
        this.gridApi.updateGridOptions({ rowData: this.rowData });
      }
    },

    //obtener los datos del backend
    async getStock() {
      try {
        // 1️⃣ Descargar el archivo Gzip desde el backend
        const response = await axios.get(
          `/reportes/get_stock?dias=${this.dias}&periodos_year_actual=${this.periodos_year_actual}&periodos_year_anterior=${this.periodos_year_anterior}`,
          {
            responseType: "arraybuffer", // Se recibe en formato binario
          }
        );

        // 2️⃣ Descomprimir el archivo Gzip con pako
        const decompressedData = pako.inflate(response.data, { to: "string" });

        // 3️⃣ Convertir el CSV a JSON con PapaParse
        const result = Papa.parse(decompressedData, { header: true });

        // 4️⃣ Asignar los datos a la tabla
        this.rowData = result.data;

        this.rowData = result.data.map((item) => ({
          ...item,
          inv_Mexicali: Number(item.inv_Mexicali),
          inv_Tijuana: Number(item.inv_Tijuana),
          inv_Ensenada: Number(item.inv_Ensenada),
          inv_Hermosillo: Number(item.inv_Hermosillo),
          inv_LaPaz: Number(item.inv_LaPaz),
          inv_Obregon: Number(item.inv_Obregon),
          inv_total: Number(item.inv_total),
          pro_Mexicali: Number(item.pro_Mexicali),
          pro_Tijuana: Number(item.pro_Tijuana),
          pro_Ensenada: Number(item.pro_Ensenada),
          pro_Hermosillo: Number(item.pro_Hermosillo),
          pro_LaPaz: Number(item.pro_LaPaz),
          pro_Obregon: Number(item.pro_Obregon),
          pro_total: Number(item.pro_total),
          stock_Mexicali: Number(item.stock_Mexicali),
          stock_Tijuana: Number(item.stock_Tijuana),
          stock_Ensenada: Number(item.stock_Ensenada),
          stock_Hermosillo: Number(item.stock_Hermosillo),
          stock_LaPaz: Number(item.stock_LaPaz),
          stock_Obregon: Number(item.stock_Obregon),
          stock_total: Number(item.stock_total),
          importancia: Number(item.importancia),
        }));
        //buscar el numero mas grande de importancia y asignarlo a maxImportance
        const importancias = this.rowData
          .map((item) => Number(item.importancia))
          .filter((n) => !isNaN(n));
        this.maxImportance =
          importancias.length > 0 ? Math.max(...importancias) : 0;

        console.log("VALOR MAXIMO DE IMPORTANCIA:", this.maxImportance);

        if (this.cargarEnvios == true) {
          this.getEnvios();
        } else {
          console.log("No se cargaron los envios");
        }

        if (this.cargarFaltantes == true) {
          this.getFaltantes();
        } else {
          console.log("No se cargaron los faltantes");
        }
        //this.getEnvios();
        //this.getFaltantes();
        this.actualizarTabla();
        this.autoSizeAllColumns();
        this.updateHeaders();
        console.log("Datos cargados en AG Grid:", this.rowData);
      } catch (error) {
        console.error("Error al procesar el archivo Gzip:", error);
      }
    },

    actualizarTabla() {
      this.applyFilter();
    },

    applyFilter() {
      if (this.showAll) {
        this.filteredRowData = this.rowData;
      } else {
        this.filteredRowData = this.rowData.filter(
          (item) => item.estado === "1"
        );
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },

    exportToCSV() {
      this.gridApi.exportDataAsCsv({
        /*no incluir la columna estado 
        columnKeys: ["codigo_producto", ],*/
        allColumns: true,
        fileName: "inventario.csv",
        skipGroups: false,
        suppressQuotes: true,
        columnSeparator: ",",
      });
    },

    toggleSucursal(sucursal) {
      this.ocultarSucursales[sucursal] = !this.ocultarSucursales[sucursal];
      const sucursalFields = [
        `inv_${sucursal}`,
        `pro_${sucursal}`,
        `stock_${sucursal}`,
      ];
      this.gridApi.setColumnsVisible(
        sucursalFields,
        !this.ocultarSucursales[sucursal]
      );
    },

    toggleColumn(col) {
      if (this.gridApi) {
        col.hide = !col.hide;
        this.gridApi.setColumnsVisible([col.field], !col.hide);
        if (col.children) {
          col.children.forEach((child) => {
            this.gridApi.setColumnsVisible([child.field], !child.hide);
          });
        }
      }
    },

    autoSizeAllColumns() {
      //ajustra las columnas a la tabla
      this.gridApi.autoSizeAllColumns();
    },

    formatNumber(params) {
      return Number(params.value).toFixed(2);
    },
    updateHeaders() {
      this.columnDefs.forEach((col) => {
        if (
          col.headerName === "INVENTARIO" ||
          col.headerName === "INVENTARIO (MAS ENVIOS)"
        ) {
          col.headerName = this.cargarEnvios
            ? "INVENTARIO (MAS ENVIOS)"
            : "INVENTARIO";
        }
        if (
          col.headerName === "PRONOSTICO" ||
          col.headerName === "PRONOSTICO (MAS FALTANTES)"
        ) {
          col.headerName = this.cargarFaltantes
            ? "PRONOSTICO (MAS FALTANTES)"
            : "PRONOSTICO";
        }
      });
      this.gridApi.updateGridOptions({ columnDefs: this.columnDefs });
    },
    rowClicked(event) {
      this.dialogVerProducto = true;
      this.selectedItem = event.data;
    },
    cerrarDialog() {
      this.dialogVerProducto = false;
    },
    async getUsuarios() {
      try {
        const response = await axios.get(`/usuarios/get_usuarios`);
        this.usuarios = response.data.usuarios;
      } catch (error) {
        console.error("Error al obtener los usuarios:", error);
      }
    },
    getNombreVendedor(vendedorId) {
      const usuario = this.usuarios.find(
        (usuario) => usuario.id === vendedorId
      );
      return usuario ? usuario.nombre_adminpaq : "Desconocido";
    },
  },
  mounted() {
    //añadir tiempo de espera para ejecutar la funcion
    //setTimeout(() => this.getEnvios(), 6000);
    //this.getEnvios();
    this.getStock();
    this.getUsuarios();
  },
  computed: {
    filteredColumnsDefs() {
      return this.columnDefs.filter(
        (col) =>
          col.field !== "estado" &&
          (!col.children ||
            col.children.every((child) => child.field !== "estado"))
      );
    },
    enviosAgrupadosFecha() {
      // Verificamos que selectedItem y la propiedad enviosPorSucursal existan
      if (
        this.selectedItem &&
        this.selectedItem.enviosPorSucursal &&
        typeof this.selectedItem.enviosPorSucursal === "object"
      ) {
        const agrupados = {};

        // Iteramos sobre cada sucursal (la clave representa el nombre de la sucursal)
        Object.keys(this.selectedItem.enviosPorSucursal).forEach((sucursal) => {
          const listaEnvios = this.selectedItem.enviosPorSucursal[sucursal];

          if (Array.isArray(listaEnvios)) {
            const agrupadosPorFecha = {};

            // Agrupamos los envíos de la sucursal por fecha
            listaEnvios.forEach((envio) => {
              const fecha = envio.fecha_llegada;
              if (!agrupadosPorFecha[fecha]) {
                agrupadosPorFecha[fecha] = 0;
              }
              agrupadosPorFecha[fecha] += envio.piezas;
            });

            // Convertimos el objeto de agrupadosPorFecha a un arreglo de objetos
            agrupados[sucursal] = Object.keys(agrupadosPorFecha).map(
              (fecha) => ({
                fecha,
                piezas: agrupadosPorFecha[fecha],
              })
            );
          }
        });

        return agrupados;
      }
      return {};
    },

    faltantesAgrupados() {
      // Verifica que exista un producto seleccionado y que tenga información de faltantes
      if (
        this.selectedItem &&
        this.selectedItem.faltantesPorVendedor &&
        typeof this.selectedItem.faltantesPorVendedor === "object"
      ) {
        const agrupados = {};
        // Recorremos cada sucursal
        Object.keys(this.selectedItem.faltantesPorVendedor).forEach(
          (sucursal) => {
            const listaFaltantes =
              this.selectedItem.faltantesPorVendedor[sucursal];
            if (Array.isArray(listaFaltantes)) {
              const agrupadosPorVendedor = {};
              // Agrupamos los faltantes de la sucursal por nombre del vendedor
              listaFaltantes.forEach((faltante) => {
                const vendedor = faltante.vendedor;
                if (!agrupadosPorVendedor[vendedor]) {
                  agrupadosPorVendedor[vendedor] = 0;
                }
                agrupadosPorVendedor[vendedor] += faltante.cantidad;
              });
              // Convertimos el objeto en un arreglo para iterar en el template
              agrupados[sucursal] = Object.keys(agrupadosPorVendedor).map(
                (vendedor) => ({
                  vendedor,
                  cantidad: agrupadosPorVendedor[vendedor],
                })
              );
            }
          }
        );
        return agrupados;
      }
      return {};
    },
    globalEnvios() {
      if (!this.selectedItem) return 0;
      let total = 0;
      // Suponiendo que en cada sucursal se guarda un arreglo de objetos con { fecha_llegada, piezas }
      if (this.selectedItem.enviosPorSucursal) {
        Object.values(this.selectedItem.enviosPorSucursal).forEach(
          (enviosArray) => {
            enviosArray.forEach((envio) => {
              total += envio.piezas;
            });
          }
        );
      }
      return total;
    },
    globalFaltantes() {
      if (!this.selectedItem) return 0;
      let total = 0;
      // Suponiendo que en cada sucursal se guarda un arreglo de objetos con { nombre_vendedor, piezas }
      if (this.selectedItem.faltantesPorVendedor) {
        Object.values(this.selectedItem.faltantesPorVendedor).forEach(
          (faltantesArray) => {
            faltantesArray.forEach((faltante) => {
              total += faltante.cantidad;
            });
          }
        );
      }
      return total;
    },
    sucursalesData() {
      return this.sucursales.map((sucursal) => ({
        sucursal,
        inventario: Number(
          this.selectedItem ? this.selectedItem[`inv_${sucursal}`] || 0 : 0
        ),
        envios: Number(
          this.selectedItem
            ? this.selectedItem[`totalEnvios_${sucursal}`] || 0
            : 0
        ),
        pronostico: Number(
          this.selectedItem ? this.selectedItem[`pro_${sucursal}`] || 0 : 0
        ),
        faltantes: Number(
          this.selectedItem
            ? this.selectedItem[`totalFaltantes_${sucursal}`] || 0
            : 0
        ),
        stock: Number(
          this.selectedItem ? this.selectedItem[`stock_${sucursal}`] || 0 : 0
        ),
      }));
    },
  },
};
</script>

<style>
.header-class {
  background-color: rgba(238, 238, 33, 0.4);
}
.header-class2 {
  background-color: rgba(100, 194, 32, 0.4);
}
.header-class3 {
  background-color: rgba(33, 145, 238, 0.4);
}
.close-btn {
  float: right;
}
/*
.global-chart-container canvas {
}*/
</style>
